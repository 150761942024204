<template>
  <div class=" card register-container">

    <el-card class="content">
      <div slot="header" class="clearfix">
        <span class="title">注册新用户</span>
        <el-button style="float: right; padding: 3px 0"
                   type="text" @click="() => $router.push({name: 'login'})">登录
        </el-button>
      </div>

      <el-form :model="form" class="form" label-width="80px">
        <el-form-item label="登录名" prop="username">
          <el-input v-model="form.username"
                    name="username"
                    placeholder="请输入用户名"/>
        </el-form-item>
        <el-form-item label="真实姓名" prop="nickname">
          <el-input v-model="form.nickname"
                    name="nickname"
                    placeholder="请输入姓名"/>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
              v-model="form.password" name="password"
              placeholder="请输入密码" show-password/>
        </el-form-item>
        <el-form-item label="手机号" prop="tel">
          <el-input v-model="form.tel"
                    name="tel"
                    placeholder="请输入手机号"/>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="form.agreement">我已阅读并同意《用户隐私授权许可》</el-checkbox>
        </el-form-item>
        <div class="footer">
          <el-button class="submit" type="primary" @click="handleLogin">
            注册
          </el-button>
          <div class="tip">如有其他疑问，请联系管理员</div>
        </div>
      </el-form>
    </el-card>

  </div>
</template>

<script>
import Cookie from 'js-cookie'

export default {
  name: 'Login',
  data() {
    return {
      showPasswordText: false,
      form: {
        username: '',
        password: '',
        nickname: '',
        tel: '',
        agreement: true,
      },
      redirect: undefined,
      loading: false
    }
  },
  watch: {},
  methods: {
    handleLogin() {
      if (!(this.form.username && this.form.password)) {
        return this.$message.error('请输入用户名和密码')
      }
      // this.loading = true

      this.$api.request('/user/create_with_username', this.form).then(data => {
        this.$message.success('欢迎加入')
        this.$dbus.account = data
        this.$dbus.uid = data.id
        Cookie.set('UID', data.id)
        Cookie.set(this.$settings.headerToken, data.token)
        this.$router.push({path: this.$settings.home})
      })

      // this.$api.account.login(this.form)
      //   .then(data => {
      //     this.loading = false
      //     this.$dbus.account = data
      //     this.$dbus.uid = data.id
      //     Cookie.set('UID', data.id)
      //     Cookie.set(this.$settings.headerToken, data.token)
      //     this.$router.push({path: this.$settings.home})
      //   }).catch(() => {
      //   this.loading = false
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.register-container {
  background-color: $bg;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100%;
  width: 100%;

  .content {
    width: 430px;

    .title {
      font-size: 17px;
    }

  }

  .form {
    padding-bottom: 20px;

    .footer {
      text-align: center;

      .submit {
        width: 200px;
      }
    }
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }


  .btn-submit {
    width: 100%;
  }

}
</style>
